import React from "react"

import Layout from "../components/layout"
import Nav from "../components/Nav"
import PageHeader from "../components/PageHeader"
import BlogList from "../components/BlogList"
import Footer from "../components/Footer"

const NotFoundPage = () => (
  <Layout>
    <Layout
      pageTitle="Rolteca | 404"
      description="Ups, parece que has sacado una pifia en exploración">
      <Nav />
      <PageHeader title="404" />
      <p>
          Parece que lo que estás buscando no está aquí
      </p>
      <Footer />
    </Layout>
  </Layout>
)

export default NotFoundPage
